
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PercentageCard',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    percentage: {
      type: Number,
      required: true
    },
    message: {
      type: String,
      required: false
    }
  },

  computed: {
    hasMessage(): boolean { return this.message != null && this.message.length > 0; },
    progressStyle(): string { return "width: " + this.percentage + "%;"; },
    progressText(): string { return this.percentage.toFixed(1); },
  }
});
