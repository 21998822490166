<template>
  <div class="card">
    <div class="card-header">
      <font-awesome-icon v-bind:icon="icon" /> {{ title }}
    </div>

    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        <div class="progress">
          <div class="progress-bar" :class="{ 'pg-warning': percentage >= 70 && percentage < 90, 'pg-danger': percentage >= 90 }" role="progressbar" :style="progressStyle" :aria-valuenow="percentage" aria-valuemin="0" aria-valuemax="100">{{ progressText }}%</div>
        </div>
      </li>
      <li v-if="hasMessage" class="list-group-item">{{ message }}</li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PercentageCard',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    percentage: {
      type: Number,
      required: true
    },
    message: {
      type: String,
      required: false
    }
  },

  computed: {
    hasMessage(): boolean { return this.message != null && this.message.length > 0; },
    progressStyle(): string { return "width: " + this.percentage + "%;"; },
    progressText(): string { return this.percentage.toFixed(1); },
  }
});
</script>

<style scoped lang="scss">
.card-header {
  padding: .775rem 1.25rem;
}

.progress {
   height: 25px;
}

.progress-bar {
  background-color: #5A73B9;

  &.pg-warning {
    background-color: #F59E00;
  }

  &.pg-danger {
    background-color: #DC3545;
  }
}
</style>
