<template>
  <div class="container">
    <h1>Welcome {{ userFullName }}</h1>
    <DemoWarningBanner />

    <div class="row">
      <div class="col-md mb-4">
        <ListCard title="Recent projects" icon="folder-open"
                  loadMessage="Loading recent projects"
                  addMessage="You don't have any projects yet. Create your first project."
                  :showAddHeader="true" :showAddBody="true"
                  v-bind:loading="loading"
                  v-bind:items="projectsList"
                  @itemclicked="onProjectItemClicked"
                  @addclicked="onProjectAddClicked" />
      </div>
      <div class="col-md mb-4">
        <ListCard title="Next presentation sessions" icon="chalkboard-teacher" editIcon="play-circle"
                  loadMessage="Loading recent presentation sessions"
                  addMessage="You don't have any upcoming presentation sessions. Create your next session."
                  :showAddHeader="true" :showAddBody="true" :showEdit="true"
                  v-bind:loading="loading"
                  v-bind:items="sessionsList"
                  @itemclicked="onSessionItemClicked"
                  @addclicked="onSessionAddClicked"
                  @editclicked="onSessionStartClicked" />
      </div>
    </div>

    <div class="row">
      <div class="col-md mb-4">
        <ListCard title="Recent 3D models" icon="cubes"
                  loadMessage="Loading recent 3D models"
                  addMessage="You don't have any 3D models yet. Create a project first."
                  :showAddHeader="false" :showAddBody="false"
                  v-bind:loading="loading"
                  v-bind:items="modelsList"
                  @itemclicked="on3DModelItemClicked"
                  @addclicked="on3DModelAddClicked" />
      </div>
      <div class="col-md mb-4">
        <PercentageCard title="Storage used" icon="hdd" :percentage="percentageOfStorageUsed" :message="messageOfStorageUsed" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import PercentageCard from '@/components/PercentageCard.vue';
import ListCard from '@/components/ListCard.vue';
import { ListCardItem } from '@/models/list-card-item';
import { PresentationSessionModel } from '@/models/presentation-session-model';
import { ThreeDimensionalModel } from '@/models/three-dimensional-model';
import { getSizeAsString } from '@/services/format-tools';
import DemoWarningBanner from '@/components/DemoWarningBanner.vue';

const MAXIMUM_ALLOWED_STORAGE_SIZE = 2147483648; // 2 GB

export default defineComponent({
  name: 'Dashboard',
  components: {
    PercentageCard,
    ListCard,
    DemoWarningBanner,
  },

  setup() {
    const store = useStore();
    return { store };
  },

  mounted() {
    this.store.dispatch(ActionTypes.LOAD_ALL_DATA).then(
      undefined,
      () => this.$router.push({ name: "Signin" })
    );
  },

  computed: {
    loading(): boolean { return this.store.state.loadingData; },
    userFullName(): string { return this.store.getters.userFullName; },
    projectsList(): ListCardItem[] { return this.store.getters.mostRecentProjectsList; },
    sessionsList(): ListCardItem[] { return this.store.getters.mostRecentSessionsList; },
    modelsList(): ListCardItem[] { return this.store.getters.mostRecentModelsList; },
    percentageOfStorageUsed(): number { return this.store.getters.storageUsed / MAXIMUM_ALLOWED_STORAGE_SIZE * 100; },
    messageOfStorageUsed(): string { return 'Currently using ' + getSizeAsString(this.store.getters.storageUsed) +
      ' out of ' + getSizeAsString(MAXIMUM_ALLOWED_STORAGE_SIZE) + ' allowed user disk storage.'; },
  },

  methods: {
    onProjectItemClicked(item: ListCardItem) {
      this.$router.push({ name: 'ProjectDetails', params: { id: item.id }});
    },

    onProjectAddClicked() {
      this.$router.push({ name: 'Projects' });
    },

    onSessionItemClicked(item: ListCardItem) {
      const session = this.getSessionById(+item.id);
      this.$router.push({ name: 'ProjectDetails', params: { id: session.projectId }, query: { sessionId: session.id }});
    },

    onSessionAddClicked() {
      this.$router.push({ name: 'Sessions' });
    },

    onSessionStartClicked(item: ListCardItem) {
      const session = this.getSessionById(+item.id);
      this.$router.push({ name: 'Meet', query: { joinToken: session.sessionJoinToken }});
    },

    on3DModelItemClicked(item: ListCardItem) {
      const model = this.getModelById(+item.id);
      this.$router.push({ name: 'ProjectDetails', params: { id: model.projectId }, query: { modelId: model.id }});
    },

    on3DModelAddClicked() {
      this.$router.push({ name: 'Projects' });
    },

    getSessionById(id: number): PresentationSessionModel {
      return this.store.getters.getSessionById(id);
    },

    getModelById(id: number): ThreeDimensionalModel {
      return this.store.getters.getModelById(id);
    },
  }
});
</script>
