function roundToLocaleString(value: number): string {
  return (Math.round(value * 100) / 100).toLocaleString();
}

export function getSizeAsString(size: number): string {
  if (size > 1024 * 1024 * 1024)
    return roundToLocaleString(size / 1024 / 1024 / 1024) + ' Go';
  else if (size > 1024 * 1024)
    return roundToLocaleString(size / 1024 / 1024) + ' Mo';
  else if (size > 1024)
    return roundToLocaleString(size / 1024) + ' ko';
  else
    return size + ' o';
}
