<template>
  <div v-if="displayDemoWarning" class="alert alert-danger" role="alert">
    <strong>Warning!<br />This instance of SecuREview is for demonstration purpose only. Do not upload sensitive/confidential 3D models.</strong>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ConfigProvider from '@/services/config-provider';

export default defineComponent({
  name: 'DemoWarningBanner',

  data: () => ({
    displayDemoWarning: ConfigProvider.value('displayDemoWarning') === 'true', // Config values are always strings.
  }),
});
</script>
