
import { defineComponent } from 'vue';
import ConfigProvider from '@/services/config-provider';

export default defineComponent({
  name: 'DemoWarningBanner',

  data: () => ({
    displayDemoWarning: ConfigProvider.value('displayDemoWarning') === 'true', // Config values are always strings.
  }),
});
